// import { t } from '@i18next/utils';
import Icon from '@react/widget/Icon';
import { t } from "i18next";

interface Props extends Record<string, any> {
  label?: string;
  placeholder?: string;
}

export default function Search({className = "", width = "w-[260px]", label = "Search", placeholder = t("Search for..."), localePath = "", ...props}:Props) {
  return (
    <div className={[
      "relative bg-white inline-block align-middle border rounded-full overflow-hidden hover:border-primary focus-within:border-primary",
      className, width
      ].join(" ")} {...props}
    >
      <form className="group/form flex w-full search" action={`${localePath}/search`}>
        <input className="input flex-1 text-sm text-gray pl-3 py-1.5 pr-0 outline-0 border-0 [&[data-value]+.reset]:block" type="text" name="q" aria-label={label} placeholder={placeholder} />
        <button title="Reset" className="reset absolute top-1.5 right-8.5 text-xs p-1 rounded-full bg-[#EEEEEF] hidden opacity-0 transition-opacity group-hover/form:opacity-100 group-focus-within/form:opacity-100" type="reset"><Icon name="close" /></button>
        <button title="Search" className="submit flex-none px-3 py-1 text-placeholder hover:text-primary" type="submit" aria-label={label}>
          <Icon className='block text-placeholder' name="search"/>
        </button>
      </form>
      <div className="absolute top-full right-0 left-0 bg-white z-50 hidden text-slate-900 rounded-xl border drop-shadow-md py-5"></div>
    </div>
  );
}